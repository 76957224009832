// import { Link } from "gatsby"
import PropTypes from "prop-types"
import React from "react"
// import { Image, Video, Transformation, CloudinaryContext } from 'cloudinary-react';

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { faPlay } from "@fortawesome/pro-solid-svg-icons/faPlay"

var classNames = require("classnames")

class VideoButton extends React.Component {
  constructor(props) {
    super(props)
  }

  render() {
    var btnClass = classNames({
      "standard-button": true,
      "button-video": true,
      "video-button": this.props.overVideo === false ? false : true,
      "z-1": this.props.overVideo,
      "sizzle-button-under": this.props.sizzleButton,
      minimal: this.props.minimal ? this.props.minimal : false,
      "btn-left": this.props.textAlign === "left",
      "btn-right": this.props.textAlign === "right",
      "btn-center": this.props.textAlign === "center"
    })

    return (
      <a
        role="button"
        aria-label="open video player"
        className={btnClass}
        onClick={this.props.toggleTheater}
      >
        <FontAwesomeIcon className="button-icon" icon={faPlay} />
        {this.props.language === "es" ? "Ver Video" : "Watch Video"}
      </a>
    )
  }
}

VideoButton.propTypes = {
  minimal: PropTypes.bool,
  textAlign: PropTypes.string,
  href: PropTypes.string,
  buttonText: PropTypes.string,
  overVideo: PropTypes.bool,
  toggleTheater: PropTypes.func
}

export default VideoButton
